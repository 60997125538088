<template>
  <div id="pending-filing-order">
    <filing-order-header
      :company-name="companyName"
      :jurisdiction-name="jurisdictionName"
      :product="product"
    />
    <div class="filing-order-container">
      <filing-order-progress
        :cancelled-statuses="cancelledStatuses"
        :current-filing-status="currentFilingStatus"
        :filing-order-statuses="filingOrderStatuses"
        :jurisdiction-name="jurisdictionName"
        :product="product"
        :current-object="currentObject"
      />
      <filing-order-details
        :cancelled-statuses="cancelledStatuses"
        :current-filing-status="currentFilingStatus"
        :is-submitted="filingOrderStatuses[2]['submitted']"
        :pending-client-actions="pendingClientActions"
        :product="product"
        :status-updated-at="statusUpdatedAt"
        @next-slide="$emit('next-slide')"
      />
    </div>
  </div>
</template>
<script>
import { epochTimeConverter } from '@/common/modules/formatters'

export default {
  name: 'PendingFilingOrder',
  components: {
    FilingOrderHeader:   () => import('@/components/Filing/PendingFiling/FilingOrderHeader'),
    FilingOrderProgress: () => import('@/components/Filing/PendingFiling/FilingOrderProgress'),
    FilingOrderDetails:  () => import('@/components/Filing/PendingFiling/FilingOrderDetails'),
  },
  props: {
    currentObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filingOrderStatuses: [ //TODO: future ticket -> make dynamic after added into client portal's pending filing
        {
          start: [
            'new',
            'oa-drafting',
            'oa-received',
            'oa-submitted',
            'oa-woi',
            'out-of-date',
            'pending-final-review',
            'pending-state-formation',
            'submitted-to-newspaper',
            'waiting-on-certified-copies',
            'waiting-on-cogs',
          ],
        },
        {
          pendingClientActions: [
            'awaiting-client-input',
            'name-not-available',
            'not-filed-in-state',
            'order-verification-required',
            'renewal-filed-by-client',
            'revoked-in-state',
            'waiting-on-cc-auth',
            'waiting-on-information',
            'waiting-on-signature',
          ],
        },
        {
          submitted: [
            'submitted-to-state',
          ],
        },
        {
          end: [
            'completed',
            'cancelled',
            'expired',
            'incomplete',
            'refunded',
          ],
        },
      ],
    }
  },
  computed: {
    cancelledStatuses() {
      const endStatuses = this.filingOrderStatuses[3].end

      return endStatuses.filter(status => status !== 'completed')
    },
    companyName() {
      return this.currentObject.company.name
    },
    currentFilingStatus() {
      return this.currentObject.status
    },
    jurisdictionName() {
      return this.currentObject.jurisdiction
    },
    pendingClientActions() {
      return this.filingOrderStatuses[1].pendingClientActions
    },
    product() {
      return this.currentObject.product
    },
    statusUpdatedAt() {
      const statusUpdatedAt = this.currentObject?.status_updated_at
      const fileDate = this.currentObject?.company?.domestic_registration?.file_date
      const updatedAt = this.currentObject.updated_at

      return statusUpdatedAt
        ? statusUpdatedAt
        : (fileDate ? epochTimeConverter(fileDate) : updatedAt)
    },
  },
}
</script>
<style lang="scss" scoped>
// Normalize
* {
  padding: 0;
  margin: 0;
}

// Component
div#pending-filing-order {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  div.filing-order-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;
    height: 25rem;
  }

  div.button-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 767px) {
  div#pending-filing-order {

    div.button-container {
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 767px) {
  .filing-order-container {
    flex-direction: column!important;
  }
}
</style>
