var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "pending-filing-order" } },
    [
      _c("filing-order-header", {
        attrs: {
          "company-name": _vm.companyName,
          "jurisdiction-name": _vm.jurisdictionName,
          product: _vm.product,
        },
      }),
      _c(
        "div",
        { staticClass: "filing-order-container" },
        [
          _c("filing-order-progress", {
            attrs: {
              "cancelled-statuses": _vm.cancelledStatuses,
              "current-filing-status": _vm.currentFilingStatus,
              "filing-order-statuses": _vm.filingOrderStatuses,
              "jurisdiction-name": _vm.jurisdictionName,
              product: _vm.product,
              "current-object": _vm.currentObject,
            },
          }),
          _c("filing-order-details", {
            attrs: {
              "cancelled-statuses": _vm.cancelledStatuses,
              "current-filing-status": _vm.currentFilingStatus,
              "is-submitted": _vm.filingOrderStatuses[2]["submitted"],
              "pending-client-actions": _vm.pendingClientActions,
              product: _vm.product,
              "status-updated-at": _vm.statusUpdatedAt,
            },
            on: {
              "next-slide": function ($event) {
                return _vm.$emit("next-slide")
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }